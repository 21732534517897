<template>
  <div>
    <div class="my-4 text-center">
      <div class="text-caption font-weight-medium">ご注文内容にお酒が含まれているお客様</div>
      <div class="text-caption font-weight-medium">下記の年齢確認を行ってください。</div>
    </div>

    <v-form ref="form">
      <div class="mx-16">
        <v-text-field
          label="生年月日"
          v-model="birthday"
          :rules="birthdayRules"
          hide-details="auto"
          hint="例：19930505"
          persistent-hint
        ></v-text-field>
        <v-text-field
          label="年齢"
          v-model="age"
          :rules="ageRules"
          hide-details="auto"
          hint="例：30"
          persistent-hint
        ></v-text-field>
      </div>

      <div class="my-4 text-center">
        <div class="text-subtitle-2">20歳未満の者の飲酒は法律で禁止されています。</div>
        <div class="text-subtitle-2">20歳未満の者に対して酒類を販売しません。</div>
      </div>

      <div class="mt-16 text-center">
        <div class="text-caption font-weight-medium">
          注文するお酒が東京都の注文者限定販売商品の場合は、
        </div>
        <div class="text-caption font-weight-medium">郵便番号を入力してください。</div>
      </div>
      <div class="mx-16">
        <v-text-field
          label="郵便番号"
          v-model="postcode"
          :rules="postcodeRules"
          hide-details="auto"
          hint="例：1057510"
          persistent-hint
        ></v-text-field>
      </div>

      <div class="my-4 text-center">
        <div class="text-caption font-weight-medium">
          上記の入力が済みましたら、「レジへ進む」を押してください。
        </div>
      </div>

      <div class="text-center">
        <v-btn color="success" class="mr-4" @click="validate">レジへ進む</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'RecoseleUserAdult',

    data() {
      return {
        birthday: '',
        birthdayRules: [
          (value) => !!value || '必須です。例：19930505',
          (value) => (value && value.length === 8) || '例：19930505',
          (value) => new RegExp('^[0-9]*$').test(value) || '数字のみです。例：19930505',
        ],
        age: '',
        ageRules: [
          (value) => !!value || '必須です。例：30',
          (value) => new RegExp('^[0-9]*$').test(value) || '数字のみです。例：30',
        ],
        postcode: '',
        postcodeRules: [
          (value) => !!value || '必須です。例：1057510',
          (value) => (value && value.length === 7) || '例：1057510',
          (value) => new RegExp('^[0-9]*$').test(value) || '数字のみです。例：1057510',
        ],
      };
    },

    methods: {
      async validate() {
        try {
          const valid = this.$refs.form.validate();
          if (!valid) return;
          if (this.age < 20 || this.$dayjs().diff(this.birthday, 'year') < 20) {
            throw '20歳未満の者の飲酒は法律で禁止されています。';
          }
          const res = await Methods.checkPostcode({
            zipcode: this.postcode,
          });
          if (
            res &&
            res.data &&
            res.data.results &&
            res.data.results[0] &&
            res.data.results[0].address1 === '東京都'
          ) {
            await Methods.UpdateUser({
              id: this.$store.state.user.user_id,
              birthday: this.birthday,
              postcode: this.postcode,
              is_adult: true,
            });
            this.$store.commit('user/set_usr_adult', {
              is_adult: true,
            });
            return this.$router.push('/pay');
          } else {
            throw '注文するお酒が東京都の注文者限定販売商品です。';
          }
        } catch (error) {
          alert(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
